.quiz-button {
  width: 100%;
  margin: 0 auto 20px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  transition: box-shadow, transform ease-in 200ms;
  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.10);
    cursor: pointer;
    transform: rotate(1deg);
    transition: box-shadow, transform ease-out 200ms;
  }

  &__image {
    width: 100%;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    text-align: center;
    color: black;
    font-family: 'Playfair Display', serif;
  }

  &.active:after {
    border-radius: 20px;
    border: 2px solid #325484;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
    display: block;
    content: "";
    background: url('/contain.jpg') no-repeat center center;
    background-size: cover;
    width: 25px;
    height: 25px;
    position: absolute;
    top: -10px;
    right: -2px;
    overflow: visible;
  }

  @media all and (max-width: 500px) {
    &__text {
      font-size: 16px;
    }
  }
}
