@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
    margin-block-start: 0;
    margin-block-end: 0;
}

b {
    font-weight: bold;
}

a {
    text-decoration: none;
}

.logo img {
    width: 300px;
}

@media all and (max-width: 500px) {
    .logo img {
        width: 250px;
    }
}
