.App {
    padding: 0 50px;

    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: calc(100vh - 51px);
    }

    &__left {
        width: 40%;
        margin-right: 30px;
        font-size: 18px;
        font-family: 'Helvetica', sans-serif;
        max-height: calc(100vh - 51px);
        display: flex;
        flex-direction: column;
        padding: 70px 0 100px 0;
        box-sizing: border-box;
        overflow-y: scroll;

        h1 {
            font-size: 45px;
            font-family: 'Playfair Display', serif;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 32px;
            font-family: 'Playfair Display', serif;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 24px;
            font-family: 'Playfair Display', serif;
            margin-bottom: 10px;
        }

        p {
            color: #4b4b4b;
            margin-bottom: 20px;
            line-height: 22px;
        }

        button {
            background: rgb(49, 48, 43);
            padding: 10px 20px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            margin-top: 20px;
            cursor: pointer;
            border: 0;
            &:focus {
                outline: none;
            }
        }
    }

    &__right {
        width: 60%;
    }

    @media all and (max-width: 700px) {
        padding: 5px 10px;
        &__wrapper {
            flex-direction: column;
            height: auto;
            padding: 20px 10px;
            align-items: flex-start;
        }
        &__left {
            width: 100%;
            height: auto;
            padding: 0;
            margin-right: 0;

            h1 {
                font-size: 38px;
                margin-bottom: 15px;
            }

            h2 {
                font-size: 28px;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 24px;
                margin-bottom: 10px;
            }

        }
        &__right {
            width: 100%;

            &.form form {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

.form form {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 30px 50px;
    border-radius: 4px;
    overflow: scroll;
    width: 50%;
    margin: 0 auto;

    label {
        font-size: 20px;
        margin-bottom: 10px;
    }

    input {
        padding: 10px 10px;
        font-size: 20px;
        border-radius: 3px;
        border: 1px solid #9b9b9b;
        outline-offset: 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
        &:focus {
            outline: none;
        }
    }

    .newsletter-checkbox {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;

        input {
            margin: 0;
            margin-inline-end: 10px;
        }
    }

    input[type="submit"] {
        border-radius: 2px;
        margin-left: auto;
        width: 100%;
        font-size: 18px;
        border: none;
        cursor: pointer;
        box-shadow: none;
        background: rgb(49, 48, 43);
        padding: 10px 20px;
        color: white;
        transition: box-shadow ease-in 200ms;
        &:hover {
            box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
            transition: box-shadow ease-out 200ms;
        }
    }
}

.quiz-buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 60px 0;

    @media all and (max-width: 700px) {
        padding: 20px 0;
    }
}

.quiz-columns {
    display: flex;
    height: 100%;
    overflow: scroll;
    padding: 0 10px;

    @media all and (max-width: 700px) {
        padding: 0;
    }
}
